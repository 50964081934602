.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  .modal-content {
    background-color: #fff;
    .modal-header {
      padding: 1vh;
      .modal-title {
        margin: 0;
      }
      .close-modal-x {
        color: #D6D6D6;
        cursor: pointer;
        font-weight: bold;
        font-size: 0.7em;
      }
    }
    .modal-body {
      padding: 3vh;
      border-top: 1px solid #eee;
    }
    .modal-footer {
      border-top: 1px solid #eee;
      padding: 1vh;
    }
  }
}
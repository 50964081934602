.countdown-timer {
	ul.counter {
		display: flex;
		align-items: center;
		justify-content: center;
		> * {
			border-radius: var(--border-radius);
			background: rgba(0, 0, 0, 0.5);
			height: 4rem;
			width: 4rem;
			padding: 0.5em;
			&:not(:last-child) {
				margin-right: 0.5em;
			}
		}
	}
	font-size: 0.8em;
	.number {
		line-height: 1.2;
		font-size: 2em;
		height: 1.25em;
	}
}

@import "../../styles/base/functions.scss";

.hero {
	min-height: 60vh;
	padding: 0;
	background-color: var(--primary-color);
	position: relative;

	.event-logo {
		margin: 0 auto;
		width: 8em;
		height: 8em;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			mix-blend-mode: darken;
		}
	}
}

.hero-background {
	position: absolute;
	z-index: 0;
	height: 100%;
	width: 100%;
}

.hero-content-wrapper {
	padding-top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include md-and-up {
		flex-direction: row;
	}
}

.content-and-feature-container {
	display: flex;
	width: 90%;
	margin: 0 auto;
	gap: var(--space-lg);

	.hero-content {
		color: #ffff;
		position: relative;
		z-index: 1;
		text-align: center;
		flex-grow: 1;
		width: 100%;
		padding: var(--space-md);

		@include md-and-up {
			width: 40%;
		}

		h4 {
			margin-bottom: var(--space-md);
		}

		h3 {
			margin: var(--space-sm) 0;
		}
	}

	.hero-feature {
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
		width: 100%;

		@include sm-and-up {
			padding-top: 0;
		}
	}

	@media only screen and (max-width: 700px) {
		flex-direction: column;
		gap: var(--space-sm);

		.hero-content,
		.hero-feature {
			width: 100%;
		}
	}
}

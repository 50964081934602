.event-item {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr max-content;
	grid-row-gap: var(--space-sm);

	.item-content {
		display: flex;
		flex-direction: column;
		gap: 0.25rem;

		.item-title {
			display: flex;
			gap: 0.25rem;
			align-items: center;
		}

		.venue-name,
		.tickets-remaining,
		.parking-passes-remaining {
			font-size: 0.9rem;
			color: var(--color-gray);
		}

		.sold-out {
			font-size: 0.9rem;
			color: #ec0000;
		}
	}

	@media only screen and (max-width: 550px) {
		display: flex;
		flex-direction: column;
	}
}

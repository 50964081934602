@import "../../styles/base/functions.scss";

.registration-links-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: var(--color-button-primary);
	border-radius: 4px;
	height: fit-content;
	margin: auto 0;

	.button.button-primary.register-link {
		border-bottom-right-radius: unset;
		border-bottom-left-radius: unset;
		border: unset;
		border-bottom: 1px solid white;
		min-width: 12rem;

		&:first-child {
			border-top-right-radius: 4px;
			border-top-left-radius: 4px;
		}

		&:last-child {
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
			border-bottom: unset;
		}

		.fontawesome-icon {
			height: 0.75rem;
			width: 0.75rem;
		}
	}
}

div.MuiModal-root .MuiBox-root {
	overflow-y: auto;
	padding-bottom: unset;
	@include custom-scrollbar;
}

#nominations-form-modal {
	display: flex;
	flex-direction: column;
	gap: var(--space-md);
	height: 100%;

	.modal-close-button {
		align-self: flex-end;
		width: fit-content;
	}

	@media only screen and (max-width: 500px) {
		gap: var(--space-sm);
	}
}

@import "../../styles/base/functions.scss";

.ticketing-hero {
	position: relative;

	.ticketing-hero-background {
		position: absolute;
		z-index: 0;
		height: 100%;
		width: 100%;
	}
}

.error {
	margin-top: var(--space-md);
	color: var(--color-error);
	text-align: center;
}

.ticketing-hero-content-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
}

.ticketing-hero-content {
	display: flex;
	gap: 4rem;
	width: 90%;

	.ticketing-details {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: var(--space-md);
		width: 40%;

		> * {
			color: #fff;
			text-align: center;
		}

		.countdown-timer {
			margin-bottom: 2rem;
		}
	}

	.ticketing-container {
		height: fit-content;
		width: 60%;
		border-radius: 0.25rem;
		box-shadow: 0px 2px 4px rgba(40, 44, 53, 0.1),
			0px 1px 17px rgba(40, 44, 53, 0.1);
		background-color: var(--color-light);
		color: hsl(0, 0%, 20%);
		align-items: flex-start;
		padding: var(--space-md);

		.header {
			text-align: center;

			h2 {
				color: var(--color-button-primary);
			}
		}

		.personal-info-form-title,
		.payment-step-title {
			margin-bottom: unset;
		}

		.confirmation-step-title {
			text-align: center;
		}

		.warning-msg {
			margin-top: unset;
		}

		section {
			display: flex;
			flex-direction: column;
			gap: 2rem;

			.row {
				display: flex;
				gap: 1rem;

				.step-number {
					display: flex;
					flex-direction: column;
					font-size: 3rem;
					line-height: 3.001rem;
					color: var(--color-button-primary);
				}

				.step-details {
					margin-top: 0.75rem;

					p {
						color: #344054;
						margin: 0.75rem 0 0;
					}
				}
			}
		}

		.app-loading-btn-loader-container {
			width: fit-content;
			padding: 0.25rem 1rem;
		}

		.total-price {
			margin-top: var(--space-sm);
			margin-bottom: unset;
			display: flex;
			justify-content: space-between;
		}

		.shooting-envelope-img-wrapper {
			width: 8rem;
			transform: rotate(-20deg);

			@include before-sm-and-up {
				margin: 0 30% 2rem auto;
			}

			@include sm-and-up {
				margin: 0 32.5% 2rem auto;
			}

			@include md-and-up {
				margin: 0 35% 2rem auto;
			}
		}
	}
}

@media only screen and (max-width: 900px) {
	.ticketing-hero {
		background-size: contain;
	}

	.ticketing-hero-content {
		flex-direction: column;

		.ticketing-details,
		.ticketing-container {
			width: 100%;
		}
	}
}

.offer-list {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
	grid-gap: var(--space-sm);
	> * {
		min-width: 0;
	}
}
.offer-link {
	color: var(--color-link-default);
	display: flex;
	gap: var(--space-sm);
	justify-content: space-between;
	border: var(--border-width) solid var(--color-light-gray);
	&:hover,
	&:focus {
		border: var(--border-width) solid currentColor;
	}
	.label {
		margin-bottom: 0.5em;
	}
}
.offer-image {
	width: 6em;
	background: #fff;
	padding: 0.75em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	img {
		mix-blend-mode: darken;
	}
}
.dark-ui {
	.offer-link {
		background: rgba(0, 0, 0, 0.2);
		&:hover {
			background: rgba(0, 0, 0, 0.3);
		}
	}
}

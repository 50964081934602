@import '../../styles/base/functions.scss';

.speakers-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
	grid-gap: var(--space-md);
	> * {
		min-width: 0;
	}
	.speaker {
		display: grid;
		grid-template-areas:
			'image details'
			'bio bio';
		grid-template-columns: 6em 1fr;
		grid-gap: var(--space-sm);
		@include sm-and-up {
			grid-template-columns: calc(6em + 2vw) 1fr;
		}

		h4 {
			font-size: 1em;
		}
	}
	.bio {
		grid-area: bio;
		max-height: 0;
		overflow: hidden;
		&.show {
			max-height: none;
		}
	}

	img {
		object-fit: cover;
		grid-area: image;
		height: 6em;
		width: 6em;
		clip-path: circle();
		@include sm-and-up {
			height: calc(6em + 2vw);
			width: calc(6em + 2vw);
		}
	}

	.details {
		grid-area: details;
	}
}

.featured-speaker {
	margin-bottom: var(--space-lg);
	@include md-and-up {
		display: flex;
	}
	h3 {
		margin-top: var(--space-sm);
	}
	h4,
	h3 {
	}
	h4 {
		font-size: 1em;
	}
	.speaker-image {
		margin: 0 0 var(--space-md) 0;
		@include md-and-up {
			width: 40%;
			flex-grow: 1;
			margin: 0 var(--space-md) 0 0;
		}
		img {
			clip-path: circle();
		}
	}
	.speaker-details {
		@include md-and-up {
			width: 60%;
		}
	}
}

@import '../../styles/base/functions.scss';

ul.guests-list {
	display: flex;
	flex-direction: column;
	gap: var(--space-lg);
	@include md-and-up {
		flex-direction: row;
	}
	> * {
		width: 100%;
		flex-grow: 1;
	}
	.guest {
		h4 {
			font-size: 1em;
		}
	}
}
.guest-header {
	height: calc(6em + 2vw);
	display: flex;
	align-items: flex-end;
	margin-bottom: var(--space-sm);
	img {
		clip-path: circle();
		height: calc(6em + 2vw);
		width: calc(6em + 2vw);
		object-fit: cover;
		margin-right: var(--space-sm);
	}
}

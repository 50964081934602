.custom-fields-form {
	display: flex;
	flex-direction: column;

	.form-title {
		margin-bottom: var(--space-sm);
	}

	select {
		padding: 0 3rem 0 0.75rem;
	}

	input,
	select {
		margin-bottom: unset;
	}

	input[type="file"] {
		margin-left: var(--space-sm);
	}

	.form-group {
		margin-bottom: var(--space-sm);
	}
}

.multiselect-checkbox-group {
	label {
		width: fit-content;
		display: flex;
		gap: var(--space-sm);
	}
}

a {
	color: var(--color-link-default);
	&:hover,
	&:focus {
		color: var(--color-link-hover);
	}
	&:visited {
		color: var(--color-link-visited);
	}
}

.nav-button-wrapper {
	width: 100%;
	position: absolute;
	z-index: 5;
	height: 3rem;
	border-top-right-radius: var(--border-radius);
	border-top-left-radius: var(--border-radius);

	#register-yourself-button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background-color: var(--color-button-primary);
		transition: 0.25s background-color ease-in-out;
		color: #fff;
		font-size: 0.85rem;
		text-transform: uppercase;
		border-top-right-radius: var(--border-radius);
		border-top-left-radius: var(--border-radius);

		&:hover {
			background-color: var(--color-button-primary-muted);
		}
	}
}

.hero-content-wrapper .registration-form-container,
.hero-content-wrapper .registration-form-container .hero-feature {
	width: 100%;
}

.hero-content-wrapper .registration-form-container .hero-feature {
	.registration-form-main-container.register-other {
		// make border radius more drastic, so that it's hidden behind button
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
	}
}

@import "../../styles/base/functions.scss";

.event-series-hero {
	position: relative;

	.event-series-hero-background {
		position: absolute;
		z-index: 0;
		height: 100%;
		width: 100%;
	}
}

.event-series-hero-content-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
}

.event-series-hero-content {
	display: flex;
	flex-direction: column;
	gap: 4rem;
	width: 90%;

	.event-series-details,
	.events-container {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.event-series-details {
		width: 60%;
		margin: 0 auto;
		align-items: center;
		text-align: center;

		> * {
			color: #fff;
		}
	}

	.events-container {
		border-radius: 0.25rem;
		box-shadow: 0px 2px 4px rgba(40, 44, 53, 0.1),
			0px 1px 17px rgba(40, 44, 53, 0.1);
		background-color: #fff;
		color: hsl(0, 0%, 20%);
		align-items: flex-start;
		padding: var(--space-md);
		height: fit-content;
		max-height: 70vh;
		overflow-y: auto;
		@include custom-scrollbar;

		.container-items {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 1rem;
		}

		.container-item {
			width: 100%;
			padding: var(--space-sm) var(--space-md);
			display: flex;
			justify-content: space-between;
			background-color: rgba(211, 211, 211, 0.1);
			border-radius: 0.25rem;

			p {
				margin: 0;
			}

			&.d-none {
				display: none;
			}
		}
	}
}

@media only screen and (max-width: 700px) {
	.event-series-hero-content .event-series-details {
		width: 100%;
	}
}

@media only screen and (max-width: 550px) {
	.event-series-hero-content .events-container .container-item {
		flex-direction: column;
		gap: var(--space-sm);
		padding: var(--space-sm);

		.register-button-wrapper,
		.register-someone-else-button-wrapper {
			width: 100%;

			button {
				width: 100%;
			}
		}
	}
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap');

h1,
h2,
h3,
h4 {
	font-family: 'Open Sans Condensed', sans-serif;
	font-weight: 700;
	text-transform: capitalize;
	line-height: 1.2;
	margin-bottom: 0.35em;
}

h1 {
	font-size: 3rem;
}
h2 {
	font-size: 2.5rem;
}
h3 {
	font-size: 2rem;
}
h4 {
	font-size: 1.5rem;
}

p {
	margin: 0.5em 0;
}

a {
	text-decoration: none;
}

h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p,
ol + p,
ul + p {
	margin-top: var(--space-sm);
}
a:hover {
	color: var(--primary-color);
}

img {
	width: 100%;
}

hr {
}
.section-title {
	display: flex;
	align-items: center;
	text-align: center;
	margin-bottom: var(--space-lg);
	width: 100%;
	&:before,
	&:after {
		flex-grow: 1;
		content: '';
		width: 0em;
		height: var(--border-width);
		background: var(--color-light-gray);
	}
	&:before {
		margin-right: var(--space-md);
	}
	&:after {
		margin-left: var(--space-md);
	}
}

.hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

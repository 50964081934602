@import '../../styles/base/functions.scss';
.video-wrapper {
	color: #fff;
	position: relative;
	top: 0;
	display: grid;
	grid-gap: var(--space-md);
	padding: var(--space-lg) var(--space-md);
	> * {
		min-width: 0;
	}
	//transition: padding 750ms ease-out;
	.offer-list {
		width: 100%;
	}
	grid-template-areas:
		'video'
		'.'
		'offers';
	grid-template-columns: 1fr;
	@include sm-and-up {
		grid-template-areas:
			'video .'
			'offers .';
		grid-template-columns: 2fr 1fr;
	}
	.video {
		grid-area: video;
		//transition: width 750ms ease;
	}
	.video-sponsor {
		grid-area: sponsor;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}
	.video-offers {
		width: 100%;
		grid-area: offers;
		@include md-and-up {
			font-size: 0.8em;
		}
	}

	.spacer {
		height: 0px;
		transition: height 750ms ease-out;
	}
}

.video-sponsor {
	.sponsor-card {
		background: none;
		border: none;
		padding: 0;
	}
	.sponsor-image {
		background: #ffffff;
		padding: 0.75em;
	}
	.sponsor-description {
		@include md-and-up {
			font-size: 0.9em;
		}
	}
}

.unstuck {
	.video-wrapper {
		max-width: $breakpoint-lg;
		margin: 0 auto;
	}
	.video {
		transition: width 750ms ease;
		//flex-grow: 0;
	}
}

.sponsor-card {
	scroll-snap-align: center;
	text-align: left;
	display: grid;
	margin-right: 5px;
	margin-left: 5px;
	min-height: 250px;
	max-height: 250px;
	grid-template-areas:
		'image label'
		'image name'
		'image links';
	grid-template-columns: 6em 1fr;
	grid-column-gap: var(--space-md);

	.sponsor-image {
		grid-area: image;
		display: flex;
		flex-direction: column;
		justify-content: center;

		img {
			mix-blend-mode: darken;
		}
	}
	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.label {
		grid-area: label;
	}
	.sponsor-name {
		grid-area: name;
	}
	.sponsor-links {
		grid-area: links;
	}
}

.sponsor-card.slides1 {
	max-width: 95%;
}

.sponsor-card.slides2 {
	max-width: 80%;
}

.sponsor-card.slides5 {
	max-width: 95%;
}
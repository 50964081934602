:root {
	//color-scheme: light dark;
	--color-gray: rgb(160, 160, 160);
	--color-light-gray: #c9c9c9;
	--color-light: #f2f2f2;
	--color-dark: hsl(221, 16%, 20%);
	--color-primary: rgb(47, 83, 151);
	--color-secondary: rgb(35, 56, 100);
	--color-error: #bf1650;
	--border-radius: 0.4rem;
	--border-width: 2px;
	--space-xs: 0.5rem;
	--space-sm: 1rem;
	--space-md: 2rem;
	--space-lg: 4rem;
	--space-xl: 8rem;
	--breakpoint-sm: $breakpoint-sm;
	--breakpoint-md: $breakpoint-md;
	--breakpoint-lg: $breakpoint-lg;

	--color-button-primary: #1976d2;
	--color-button-primary-muted: #1565c0;
	--color-link-default: #0277bd;
	--color-link-hover: #01579b;
	--color-link-visited: #5c6bc0;
}

.dark-ui {
	background: var(----color-dark);
	color: var(--color-light);
	--color-link-default: #64b5f6;
	--color-link-hover: #90caf9;
	--color-link-visited: #7986cb;
}

*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
	font-size: 0.875rem;
	@include sm-and-up {
		font-size: 1rem;
	}
	@include md-and-up {
		font-size: 1.125rem;
	}
}

body {
	min-height: 100vh;
	font-family: "Open Sans", sans-serif;
	line-height: 1.5;
	background-color: var(--color-light);
	color: var(--color-dark);
	margin: auto 0;
}

#root {
	min-height: 100vh;
}

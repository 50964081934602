@import '../../styles/base/functions.scss';

.nav {
	font-family: 'Open Sans Condensed';
	font-weight: 600;
	margin: 0;
	position: sticky;
	top: 0;
	z-index: 2;
	display: grid;
	grid-template-areas:
		'title toggle'
		'links links';
	grid-template-columns: 1fr 4em;
	padding: var(--space-sm);
	@include md-and-up {
		grid-template-areas: 'title spacer links';
		grid-template-columns: 14em auto 1fr;
	}
}
.site-title {
	grid-area: title;
	display: flex;
	align-items: center;
	img {
		width: 100%;
		height: 3em;
		object-fit: contain;
	}
}
.links-wrapper {
	text-align: center;
	justify-self: center;
	align-self: center;
	max-height: 0;
	overflow: hidden;
	grid-area: links;
	margin-top: 15px;
	width: 100%;
	@include md-and-up {
		max-height: inherit;
		justify-self: end;
		width: auto;
	}
}
.links-list {
	font-size: 1.5em;
	@include md-and-up {
		font-size: 1em;
		display: flex;
		gap: var(--space-sm);
	}

	li {
		margin-bottom: 30px;
	}

}
.nav-toggle {
	grid-area: toggle;
	padding: 0;
	background: none;
	height: auto;
	border: none;
	&:hover,
	&:focus {
		background: none;
		border: none;
	}
	* {
		pointer-events: none;
	}
}

.toggle-icon {
	height: 4em;
	padding: 1em;
	margin: 0;
	width: 4em;

	@include md-and-up {
		display: none;
	}

	span {
		background-color: currentColor;
		content: '';
		display: block;
		height: 3px;
		margin: 0.5em 0;
		//transition: all .2s ease-in-out;

		&:first-child {
			margin: 0 0 0.7em 0;
		}
		&:last-child {
			margin: 0.7em 0 0 0;
		}
	}
}

[data-expanded='true'] {
	.links-wrapper {
		max-height: 100vh;
	}
	.toggle-icon {
		span {
			transform: scale(0);
		}

		span:first-child {
			transform: translateY(1em) rotate(135deg);
		}

		span:last-child {
			transform: translateY(-1em) rotate(-135deg);
		}
	}
}

:target::before {
  content: "";
  display: block;
  height: 220px; /* fixed header height*/
  margin: -220px 0 0; /* negative fixed header height */
}
@import '../../styles/base/functions.scss';

.intro {
	margin: var(--space-lg) 0;
	.intro-copy {
		font-size: 1.125em;
		@include sm-and-up {
			padding: 0;
			font-size: 1.25em;
		}
	}
}

#intro {
	.image-style-align-left {
    float: left;
    margin-right: 10px;
	}

	.image-style-align-right {
			float: right;
			margin-left: 10px;
	}

	.image-style-align-center {
			display: block;
			margin-left: auto;
			margin-right: auto;
	}

	p {
		strong {
			font-weight: bold;
		}

		i {
			font-style: italic;
		}
	}
}

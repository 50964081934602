@import '../../styles/base/functions.scss';

.resource-list[center-resources='false'] {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: var(--space-md);

	@include sm-and-up {
		grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
	}

	> * {
		min-width: 0;
	}
}

.resource-list[center-resources='true'] {
	display: flex;
	justify-content: center;
	gap: var(--space-md);

	> * {
		min-width: 0;
	}
}
.resource-link {
	text-align: center;
	color: var(--color-link-default);
	display: block;
	height: 100%;

	border: var(--border-width) solid var(--color-light-gray);
	&:hover,
	&:focus {
		border: var(--border-width) solid currentColor;
	}
	svg {
		margin: 0 auto var(--space-sm) auto;
	}
}

.nominations-form-wrapper {
	grid-column: span 2;
	height: 100%;

	.app-loader-wrapper {
		height: 80%;
	}

	.nomination-modal {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.nominations-details {
		display: flex;
		justify-content: space-between;
		gap: var(--space-md);
		margin: var(--space-sm) 0;
		color: var(--color-gray);

		.manifest-link {
			color: var(--color-button-primary);
			display: flex;
			align-items: center;
			gap: var(--space-xs);

			.fontawesome-icon {
				height: 0.75rem;
				width: 0.75rem;
			}

			&:hover {
				color: var(--color-button-primary-muted);
			}
		}
	}

	.form-status {
		.error-msg,
		.form-submit-message {
			text-align: center;
			margin-bottom: var(--space-sm);
		}

		.form-submit-message {
			color: var(--color-button-primary);
		}
	}

	.stepper-controls {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		flex-grow: 1;
		margin-top: var(--space-sm);
		padding-bottom: var(--space-md);

		.button {
			width: fit-content;
			min-width: 6rem;
		}
	}

	@media only screen and (max-width: 500px) {
		.MuiStepper-root {
			flex-direction: column;

			.MuiStep-root {
				width: 10rem;
				margin-bottom: var(--space-xs);
			}
		}
	}
}

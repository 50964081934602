// Form
// ––––––––––––––––––––––––––––––––––––––––––––––––––

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='week'],
input:not([type]),
textarea,
select,
.multi-select-input {
	font-size: 1rem;
	-webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
	background-color: #fff;
	border: var(--border-width) solid var(--color-light-gray);
	border-radius: var(--border-radius);
	box-shadow: none;
	box-sizing: inherit; // Forced to replace inherit values of the normalize.css
	height: 2.5rem;
	padding: 0.75rem;
	margin: 0.25em 0 0.75em 0;
	width: 100%;

	&:focus {
		border-color: var(--color-primary);
		outline: 0;
	}
}

select {
	background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23' + str-slice(inspect(var(--color-light-gray)), 2) + '" d="M0,0l6,8l6-8"/></svg>') center right no-repeat;
	padding-right: 3rem;

	&:focus {
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23' + str-slice(inspect(var(--color-primary)), 2) + '" d="M0,0l6,8l6-8"/></svg>');
	}

	&[multiple] {
		background: none;
		height: auto;
	}
}

textarea {
	min-height: 6.5rem;
}
label {
	display: block;
	width: 100%;
}
label,
.label {
	font-size: 0.9em;
	font-family: 'Open Sans Condensed';
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-weight: 700;
}
legend {
	display: block;
	font-size: 1rem;
	font-weight: 700;
}

fieldset {
	border-width: 0;
	padding: 0;
}

input[type='checkbox'],
input[type='radio'] {
	display: inline;
}

.label-inline {
	display: inline-block;
	font-weight: normal;
	margin-left: 0.5rem;
}

.form-row {
	@include sm-and-up {
		display: flex;
		> * {
			margin-top: 0;
		}
		> *:not(:last-child) {
			//width: 100%;
			//flex-grow: 1;

			margin-right: var(--space-sm);
		}
	}
}

.error-page-container {
  display: grid;
  grid-template-rows: 10vh 20vh;
  padding: 100px;
  height: 100vh;
  background-color: #FFFFFF;
  align-items: center;

  .img-wrapper {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOgQ4sSYccBxa4Ps9Tlu4zViU_4EkCixC2LQ&usqp=CAU');
  }

  .msgs-wrapper {
    .fixed-msg-wrapper {
      text-align: center;
      font-weight: bold;
      font-size: 1.31em;
      margin-bottom: 20px;
    }

    .error-msg-wrapper {
      align-self: start;
      ul {
        list-style: disc inside;
      }
    }
  }
}

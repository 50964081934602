@import '../../styles/base/functions.scss';

.hosted-by-nav-main-container {
  padding: 1vh;
  height: 100%;
  padding: 1vh;
  display: grid;
  grid-template-columns: 1fr 80px 1fr;
  grid-template-rows: 10px 50px 10px;
  grid-template-areas:
  "t t t"
  ". l ."
  "d d d";
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  @include before-sm-and-up {
    grid-template-columns: minmax(40px, max-content) 80px 10px minmax(90px, max-content);
    grid-template-rows: 100%;
    grid-template-areas: "t l s d";
    grid-gap: 5px;
  }
  .hosted-by-text {
    grid-area: t;
    font-weight: bold;
    font-size: clamp(8px, 1.2vw, 12px);
    text-align: center;
  }
  .logo {
    grid-area: l;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .separator {
    grid-area: s;
    display: none;
    @include before-sm-and-up {
      display: inline-block;
    }
  }
  .description {
    grid-area: d;
    font-weight: bold;
    align-self: center;
    font-size: clamp(10px, 1.5vw, 14px);
  }
}
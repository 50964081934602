@import '../../styles/base/functions.scss';
.attendee-list {
	display: grid;
	grid-column-gap: var(--space-md);
	grid-row-gap: 5em;
	grid-template-columns: 1fr 1fr;
	padding-top: var(--space-md);
	@include sm-and-up {
		grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));
	}

	> * {
		min-width: 0;
	}
}
.attendee {
	position: relative;
	display: flex;
	flex-direction: column;
}
.attendee-image {
	position: absolute;
	width: 6em;
	height: 6em;
	left: calc(50% - 3em);
	top: -3em;
	border: var(--border-width) solid var(--color-light-gray);
	border-radius: 50%;
	overflow: hidden;
	background: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.attendee-details {
	text-align: center;
	margin-top: 3em;
	margin-bottom: var(--space-sm);

	h3 {
		font-size: 1.25em;
	}
	h4 {
		font-size: 0.8em;
	}
}
.attendee-action {
	margin-top: auto;
	text-align: center;
	a {
		font-size: 0.9em;
	}
}

@import '../../styles/base/functions.scss';

.sponsored-by-nav-main-container {
  height: 100%;
  .sponsored-by-nav-content {
    height: 100%;
    padding: 1vh;
    display: grid;
    grid-template-columns: 1fr 80px 1fr;
    grid-template-rows: 10px 50px 10px;
    grid-template-areas:
    "t t t"
    ". l ."
    "o o o";
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    @include before-sm-and-up {
      grid-template-columns: minmax(40px, max-content) 80px 10px minmax(90px, max-content);
      grid-template-rows: 100%;
      grid-template-areas: "t l s o";
      grid-gap: 5px;
    }
    .sponsored-by-text {
      grid-area: t;
      font-weight: bold;
      font-size: clamp(8px, 1.2vw, 12px);
      @include before-sm-and-up {
        font-size: clamp(6px, 1.2vw, 12px);
      }
      text-align: center;
    }
    .logo {
      grid-area: l;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .separator {
      grid-area: s;
      display: none;
      @include before-sm-and-up {
        display: inline-block;
      }
    }
    .special-offer-link {
      grid-area: o;
      justify-self: center;
      @include before-sm-and-up {
        justify-self: start;
      }
      a {
        text-decoration: underline;
        cursor: pointer;
        .offer-link-content-container {
          display: grid;
          grid-template-columns: minmax(min-content, max-content) 18px;
          justify-items: center;
          align-items: center;
          grid-gap: 3px;
          grid-template-rows: 100%;
          div {
            font-weight: bold;
            align-self: center;
            font-size: clamp(10px, 1.5vw, 14px);
            @include before-sm-and-up {
              font-size: clamp(8px, 1.5vw, 14px);
            }
          }
          svg {
            justify-self: center;
            @include before-sm-and-up {
              justify-self: start;
            }
          }
        }
      }
    }
  }
}

.special-offer-modal-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 80vh;
  width: 80vw;
  gap: 3vh;
  justify-content: space-between;
  @include sm-and-up {
    display: grid;
    grid-template-columns: 35% 62%;
    overflow-y: visible;
    grid-gap: 0;
    width: 75vw;
  }
  .special-offer-modal-left-col {
    display: flex;
    flex-direction: column;
    gap: 3vh;
    .social-media-links {
      display: flex;
      justify-content: space-between;
      a {
        color: inherit;
      }
    }
    p {
      font-size: 0.7em;
      margin: 0;
    }
  }
  .special-offer-modal-right-col {
    display: flex;
    flex-direction: column;
    gap: 3vh;
    justify-content: space-between;
    @include sm-and-up {
      gap: 0;
      justify-content: flex-start;
    }
    .sponsor-offers-form-container {
      display: flex;
      flex-direction: column;
      gap: 3vh;
      @include sm-and-up {
        display: grid;
        grid-template-columns: 48.5% 48.5%;
        place-items: stretch;
        justify-content: space-between;
        grid-gap: 0;
        margin-top: 2vh;
      }
      .sponsor-offers-container {
        background-color: #E6E6E6;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        padding: 1.5vh;
        h5 {
          font-weight: bold;
        }
        p {
          font-weight: bold;
          margin: 0;
          font-size: small;
        }
        li {
          margin-top: 2vh;
        }
      }
      .form-container {
        background-color: #E6E6E6;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        padding: 1.5vh;
        h5 {
          font-weight: bold;
        }
        input {
          margin: 0;
          margin-top: 0.5vh;
        }
        textarea {
          margin: 0;
          margin-top: 0.5vh;
          max-height: 10vh;
          min-height: 10vh;
          height: 10vh;
          @include sm-and-up {
            max-height: 7vh;
            min-height: 7vh;
            height: 7vh;
          }
        }
      }
    }
  }
}
@import '../../styles/base/functions.scss';

.embedded-resources-container {
  margin-top: 0;
}

.sticky-container {
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  grid-gap: 0;
  .sticky-video {
    display: none;
    flex-shrink: 0;
    transition: none;
    width: 50%;
    @include before-sm-and-up {
      display: block;
    }
    @include sm-and-up {
      width: 35%;
    }
    @include md-and-up {
      width: 16em;
    }
    flex-shrink: 0;
  }
  .hosted-and-sponsored-by {
    display: flex;
    flex-direction: column;
    width: 100%;
    .hosted-by {
      height: 50%;
    }
    .sponsored-by {
      height: 50%;
    }
  }
}
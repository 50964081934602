.nominations-form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: var(--space-sm);

	.form-title {
		grid-column: span 2;
	}

	.error-msg {
		margin-top: unset;
		margin-bottom: var(--space-sm);
	}

	div,
	p,
	label {
		font-size: 0.9rem;
	}

	select {
		padding: 0 3rem 0 0.75rem;
	}

	.request-pp-checkbox-wrapper {
		display: flex;
		gap: 0.5rem;
		margin-bottom: 1rem;

		.checkbox-inner-wrapper {
			position: relative;

			#request-pp-checkbox {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 2rem;
				width: 2rem;
				z-index: 5;

				&:checked ~ .checkmark:after {
					display: block;
				}
			}

			.checkmark {
				display: block;
				position: relative;
				top: 0;
				left: 0;
				height: 2rem;
				width: 2rem;
				margin: 0.45rem 0;
				background-color: #ffffff;
				border-radius: var(--border-radius);
				border: 1px solid lightgrey;
			}

			.checkmark:after {
				content: " ";
				position: absolute;
				left: 37.5%;
				top: 15.5%;
				display: none;
				width: 0.5rem;
				height: 1rem;
				border: solid white;
				border-width: 0 2px 2px 0;
				border-color: var(--color-button-primary);
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
			}
		}
	}

	.submit-button-wrapper {
		grid-column: span 2;
	}

	@media only screen and (max-width: 600px) {
		display: flex;
		flex-direction: column;
	}
}

.page {
	min-height: 100vh;
	position: relative;
	display: grid;
	grid-template-columns: var(--space-md) 1fr var(--space-md);
	grid-template-rows: auto 1fr auto;

	> * {
		min-width: 0;
		grid-column: 2 / 3;
		&.alignwide {
			grid-column: 2 / 3;
		}
		&.alignfull,
		&.section-white {
			grid-column: 1 / 4;
		}
	}
	@include sm-and-up {
		grid-template-columns: 8vw 1fr minmax(20px, #{$breakpoint-sm}) 1fr 8vw;
		> * {
			grid-column: 3;
			&.alignwide {
				grid-column: 2 / 5;
			}
			&.alignfull,
			&.section-white {
				grid-column: 1 / 6;
			}
		}
	}
	> * {
		margin: var(--space-lg) auto;
	}
}

.aligncenter {
	width: 100%;
	max-width: #{$breakpoint-sm};
	margin: var(--space-lg) auto;
}

.alignwide {
	width: 100%;
	margin: var(--space-lg) auto;
	@include md-and-up {
		max-width: #{$breakpoint-lg};
	}
}

.alignfull {
	max-width: none;
	width: 100%;
	margin: 0 auto;
}

.section-white {
	max-width: none;
	width: 100%;
	margin: 0 auto;
	background: #fff;
	padding: 0 var(--space-md);
	&.alignwide {
		> * {
			width: 100%;
			margin: var(--space-lg) auto;
			@include md-and-up {
				max-width: #{$breakpoint-lg};
			}
		}
	}
	&.aligncenter {
		> * {
			width: 100%;
			max-width: #{$breakpoint-sm};
			margin: var(--space-lg) auto;
		}
	}
}

.app-loader-wrapper {
	width: 100%;
	height: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@import '../../styles/base/functions.scss';
.schedule-event-list {
}

.schedule-event {
	border-top: var(--border-width) solid var(--color-light);
	margin: 1.5em 0;
	padding: 1.5em 0 0 0;
	&:last-child {
		margin-bottom: 0;
	}
	h4 {
		font-size: 1.2rem;
		margin: 0;
	}
}

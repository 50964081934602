.plus-ones-wrapper {
	display: grid;
	grid-template-columns: repeat(2, max-content);
	gap: 5px;
	align-items: end;
}

.register-other-form {
	margin-top: 3rem; // offset for button at top of form

	select[name="guestRep"] {
		padding: 0 3rem 0 0.75rem;
	}

	.form-submit-succeeded {
		margin-bottom: 1.5rem;
	}

	.form-submit-succeeded .form-submit-message,
	.invite-form-counter {
		text-align: center;
		color: #146fb7;
		font-size: 0.9rem;
	}

	.submit-button-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 1rem;

		.submit-button {
			width: 75%;
		}
	}

	.error-msg {
		margin-top: 0;
	}
}

.form-title {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.not-you-link {
		color: #146fb7;
		cursor: pointer;
	}
}

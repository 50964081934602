.payment-step-loader {
	width: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: var(--space-sm);
}

.price-breakdown {
	h3 {
		border-bottom: 1px solid lightgrey;
	}

	.ticket-price,
	.app-fee {
		display: flex;
		justify-content: space-between;
		margin-bottom: unset;
	}

	.ticket-price {
		border-bottom: 1px solid lightgrey;
		padding-bottom: 0.5rem;
	}

	.app-fee {
		border: unset;
	}

	.total-price {
		border: unset;
		border-top: 4px solid lightgrey;
		margin-top: 0.5rem !important;
		padding-top: 0.5rem;
	}
}

$breakpoint-before-sm: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;

@mixin before-sm-and-up {
	@media (min-width: #{$breakpoint-before-sm}) {
		@content;
	}
}

@mixin sm-and-up {
	@media (min-width: #{$breakpoint-sm}) {
		@content;
	}
}

@mixin md-and-up {
	@media (min-width: #{$breakpoint-md}) {
		@content;
	}
}

@mixin lg-and-up {
	@media (min-width: #{$breakpoint-lg}) {
		@content;
	}
}

@mixin custom-scrollbar {
	// scrollbar custom css
	&::-webkit-scrollbar {
		background: transparent;
		border-radius: 20px;
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		margin: var(--space-sm) 0;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
		width: 5px;
		background-color: lightgrey;
	}
}

@import "../../styles/base/functions.scss";

.registration-form-main-container {
	color: var(--color-dark);
	background: var(--color-light);
	padding: var(--space-md);
	border-radius: var(--border-radius);
	width: 100%;

	.loader-wrapper {
		display: flex;
		justify-content: center;
	}

	.active-step-container {
		.confirmation-step-container {
			.heading {
				text-align: center;
			}

			.remaining-spots-msg {
				color: var(--color-error);
				text-align: center;
			}

			.subheading {
				margin-top: 30px;
				font-size: 1em;
				text-align: center;
			}

			.confirmation-btns {
				display: flex;
				justify-content: center;
				gap: 50px;
				margin-top: 40px;

				button {
					width: 45%;
				}
			}

			.registration-details {
				.header {
					text-align: center;
					border-bottom: 1px solid #d8d8d8;

					.colored {
						color: var(--color-button-primary);
					}

					.heading {
						margin-bottom: 0.75rem;
					}

					p {
						margin: 0 0 1rem;
					}
				}

				.header p,
				section .row h4 {
					color: #101828;
				}

				section {
					display: flex;
					flex-direction: column;
					gap: 2rem;
					margin: 1rem 0 3rem;

					.row {
						display: flex;
						gap: 1rem;

						.step-number {
							display: flex;
							flex-direction: column;
							font-size: 3rem;
							line-height: 3.001rem;
							color: var(--color-button-primary);
						}

						.step-details {
							margin-top: 0.75rem;

							p {
								color: #344054;
								margin: 0.75rem 0 0;
							}
						}
					}
				}
			}
		}

		.email-confirmation-step-container {
			.shooting-envelope-img-wrapper {
				width: 8rem;
				transform: rotate(-20deg);

				@include before-sm-and-up {
					margin: 0 30% 2rem auto;
				}

				@include sm-and-up {
					margin: 0 32.5% 2rem auto;
				}

				@include md-and-up {
					margin: 0 35% 2rem auto;
				}
			}

			.email-confirmation-title {
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 1.25rem;
				margin-top: 30px;

				@include md-and-up {
					margin-top: 0;
				}

				h2 {
					margin-bottom: 0;
					font-size: 2rem;
				}

				.registration-email-address {
					font-size: 1.25rem;
				}
			}

			.email-confirmation-details {
				font-size: 0.9rem;
				margin-bottom: 2rem;
			}

			.tickets-ready-btn-container {
				display: flex;
				justify-content: center;
				margin-bottom: 2rem;

				button {
					padding-top: 0.4rem;
				}
			}

			.centered-section {
				display: grid;
				justify-content: center;
				margin-bottom: 20px;
			}

			.email-confirmation-checkbox-wrapper {
				display: flex;
				gap: 0.5rem;
				margin-bottom: 1rem;

				.checkbox-inner-wrapper {
					position: relative;

					#email-confirmation-checkbox {
						position: absolute;
						opacity: 0;
						cursor: pointer;
						height: 2rem;
						width: 2rem;
						z-index: 5;
					}

					.checkmark {
						display: block;
						position: relative;
						top: 0;
						left: 0;
						height: 1.5rem;
						width: 1.5rem;
						min-width: 32px;
						min-height: 32px;
						background-color: #ffffff;
						border-radius: 50%;
						border: 1px solid lightgrey;
					}

					.checkmark:after {
						content: " ";
						position: absolute;
						left: 35%;
						top: 12.5%;
						display: none;
						width: 0.5rem;
						height: 1rem;
						border: solid white;
						border-width: 0 2px 2px 0;
						border-color: #055ba4;
						transform: rotate(45deg);
						-webkit-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
					}

					#email-confirmation-checkbox:checked ~ .checkmark:after {
						display: block;
					}
				}

				p {
					margin: 0;
					font-size: 0.9rem;
					display: flex;
					align-items: center;
				}
			}

			.email-confirmation-thumbsup {
				display: flex;
				justify-content: center;
				align-items: center;

				.thumbsup-img-wrapper {
					width: 40%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					img {
						width: 4rem;
					}
				}
			}

			.confirming-tickets-spinner-container {
				display: grid;
				grid-template: repeat(2, max-content) / 100%;
				justify-items: center;

				.loading-spinner {
					margin-top: 1rem;
				}
			}
		}

		.invite-form-step-container {
			.form-title {
				display: grid;
				align-items: center;

				@include md-and-up {
					grid-template: 100% / repeat(2, max-content);
					justify-content: space-between;
				}

				.not-you-link {
					color: #146fb7;
					cursor: pointer;
				}
			}

			.form-row {
				display: flex;
				column-gap: 10px;

				div {
					margin-right: 0;
					width: 50%;
				}
			}

			input {
				margin-bottom: 0px;
			}

			input.disabled-field {
				color: #a2a2a2;
				background-color: #f2f2f2;
			}

			label {
				margin-top: 10px;
				margin-bottom: 0px;
			}

			select {
				padding: 0 3rem 0 0.75rem;
			}

			.submit-btn-wrapper {
				width: 100%;
				text-align: center;
				padding-top: 20px;
			}
		}

		.payment-step-container {
			text-align: center;

			.heading {
				margin-bottom: 30px;
			}

			.subheading {
				display: flex;
				flex-direction: column;
				gap: 20px;
			}
		}

		.plus-ones-step-container {
			display: flex;
			flex-direction: column;
			text-align: center;

			.remaining-plus-ones-message {
				text-align: center;
				color: var(--color-error);
				margin-top: 10px;
				margin-bottom: 10px;
				font-size: 0.7em;
			}

			.response-error-msg {
				margin-top: 20px;
			}

			.plus-one-form-wrapper {
				margin-top: 20px;

				.submit-btn-wrapper {
					display: flex;
					justify-content: center;
					margin-top: 20px;
				}
			}
		}

		.icebreaker-questions-step-container {
			.subheading {
				margin-bottom: 40px;
			}

			.questions-wrapper {
				max-height: 400px;
				overflow-y: auto;
				overflow-x: hidden;

				.multi-select-input,
				input,
				select {
					height: 3rem;
				}
			}
		}

		.hide-bio-step-container {
			.hide-bio-field-wrapper {
				display: flex;
				justify-content: center;
				margin-top: 40px;

				label {
					width: auto;
				}
			}
		}

		.connection-step-container {
			.subheading {
				margin-bottom: 30px;
			}

			.content {
				display: flex;
				gap: 20px;

				.preview {
					width: 50%;
				}

				.settings {
					display: flex;
					flex-direction: column;
					gap: 20px;
					width: 50%;

					.setting {
						display: flex;
						gap: 10px;

						.number {
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 100%;
							border-style: solid;
							border-width: 2px;
							border-color: #2986cb;
							min-width: 35px;
							max-width: 35px;
							min-height: 35px;
							max-height: 35px;
							font-size: 1.5em;
						}

						.setting-content {
							display: flex;
							flex-direction: column;
							font-size: 1em;

							.linkedin-btn {
								justify-content: flex-start;
								padding: 0;
								font-size: 0.8em;
							}

							.hide-bio-label {
								font-size: 0.8em;

								svg {
									width: 20px;
								}
							}

							.see-more-attendees-btn-wrapper {
								display: flex;
								margin-top: 10px;
							}
						}
					}
				}
			}
		}

		.waiver-step-container {
			.btn-wrapper {
				display: flex;
				justify-content: center;
				margin-top: 40px;
			}

			.agree-input-wrapper {
				text-align: center;
				margin-top: 40px;
				margin-left: 20px;
			}
		}
	}

	.stepper-wrapper {
		margin-top: 40px;

		.stepper-btn {
			color: #1875d1;
		}
	}

	@media only screen and (max-width: 900px) {
		.form-row {
			flex-direction: column;

			> div {
				width: 100% !important;
			}
		}
	}
}

.thank-you-msg-container {
	color: var(--color-dark);
	background: var(--color-light);
	padding: var(--space-md);
	max-width: 30em;
	border-radius: var(--border-radius);

	.thank-you-msg-wrapper {
		font-weight: bold;
		white-space: pre-line;
		text-align: center;
		font-size: 0.85em;
	}

	.thank-you-msg-links-wrapper {
		padding-top: 20px;
		padding-bottom: 20px;

		.link-wrapper {
			text-align: center;
		}
	}
}

.sponsor-widget-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 20% 10% 40% 30%;
	height: 500px;

	@include sm-and-up {
		height: 570px;
	}

	@include md-and-up {
		height: 640px;
	}

	.logo {
		width: 100%;
		height: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	.headline {
		font-weight: bold;
		text-align: center;
	}

	.form-wrapper {
		.submit-btn-wrapper {
			margin-top: 10px;
			text-align: center;
		}

		input {
			margin-bottom: 0px;
		}

		label {
			margin-top: 10px;
			margin-bottom: 0px;
		}
	}

	.submitted-form-msg-wrapper {
		font-weight: bold;
		text-align: center;
		align-self: center;
		white-space: pre-line;
	}

	.iframe-wrapper {
		display: grid;
		align-items: center;

		iframe {
			width: 100%;
			height: 85%;
		}
	}
}

.error-msg {
	color: var(--color-error);
	margin: 0px;
	margin-bottom: 10px;
	margin-top: 10px;
	font-size: 0.9em;
}

.error-msg::before {
	display: inline;
	content: "⚠ ";
}

.warning-msg {
	background-color: #fffaeb;
	color: #dc6803;
	margin: 0px;
	margin-bottom: 10px;
	margin-top: 10px;
	font-size: 0.9em;
	border-radius: 15px;
	padding: 15px;
}

.is-minor-field {
	margin-left: 8px;
}

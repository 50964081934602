.sponsor-ribbon {
	padding: var(--space-md);

	.swiper-wrapper {
		align-items: stretch;
	}
	.swiper-slide {
		height: unset;
		align-self: stretch;
		display: flex;
		flex-direction: column;
		.sponsor-card {
			flex-grow: 1;
		}
	}

	.thank-you-sponsors-message {
		color: #ffffff;
		font-weight: bold;
		font-size: 2.5em;
		text-align: center;
		padding-top: 2vh;
	}
}

.sponsor-ribbon.sponsor-ribbon-slider {
	display: grid;
	justify-content: center;
	grid-gap: 5%;
}
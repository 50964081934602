.button {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: var(--space-xs);
	font-size: inherit;
	padding: 0.25rem 0.5rem;
	width: 100%;
	height: 2rem;
	transition: background-color 0.25s ease-in-out;
	border-radius: 4px;

	&.button-primary {
		color: #fff;
		background-color: var(--color-button-primary);
		border: 1px solid var(--color-button-primary);

		&:hover {
			background-color: var(--color-button-primary-muted);
		}
	}

	&.button-secondary {
		color: inherit;
		background-color: var(--color-light-gray);
		border: 1px solid var(--color-gray);

		&:hover {
			background-color: var(--color-gray);
		}
	}

	&[disabled] {
		pointer-events: none;
		filter: opacity(0.6);
	}

	&:hover {
		cursor: pointer;
	}
}

@import '../../styles/base/functions.scss';

.landing-footer-main-container {
  display: flex;
  flex-direction: column;
  padding-top: 5vh;
  padding-bottom: 5vh;
  text-align: center;
  padding-left: 20vw;
  padding-right: 20vw;
  @include md-and-up {
    padding-left: 30vw;
    padding-right: 30vw;
  }
  .landing-footer-social-media-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5vh;
    .social-icons {
      display: flex;
      justify-content: center;
      gap: 2vw;
    }
  }
  .landing-footer-contact-container {
    margin-bottom: 5vh;
  }
  .landing-footer-rights-reserved-container {
    a {
      text-decoration: underline;
    }
  }
}

.landing-links {
  display: flex;
  gap: 1vw;
}

.text-weight {
  font-weight: bold;
}
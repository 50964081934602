.sponsor-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
	grid-gap: var(--space-md);
	> * {
		min-width: 0;
	}
	.sponsor-item {
	}
}

.sponsor-header {
	display: flex;
	align-items: center;
	margin-bottom: var(--space-sm);
	img {
		width: 6em;
		object-fit: contain;
		margin-right: var(--space-sm);
	}
}
.sponsor-list-wrapper {
	margin-bottom: var(--space-md);
	h3 {
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: var(--space-sm);
		font-size: 1.25em;
		&:after {
			opacity: 0.3;
			margin-left: var(--space-md);
			flex-grow: 1;
			content: '';
			width: 0em;
			height: var(--border-width);
			background: var(--color-light-gray);
		}
	}
}
